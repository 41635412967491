import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grow from "@mui/material/Grow";

export default function ProjectCard({ title, text, image, site, more }) {
  return (
    <Grow in={true}>
      <Card
        sx={{ maxWidth: 800 }}
        className="p-1 ml-2 mr-2 mt-4 mb-4 md:w-4/5 z-50"
      >
        <CardMedia
          component="img"
          sx={{ maxHeight: "500px", pointerEvents: "none" }}
          image={`${image}`}
          alt={`${title}`}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="">
            {text}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            href={site}
            rel="noreferrer"
            target="_blank"
            size="medium"
            sx={{
              backgroundColor: "black",
              color: "#fff",
              fontSize: "1rem",
              margin: "auto",
              padding: "0.5rem",
              border: "1px black solid",
              borderRadius: "6px",
              cursor: "pointer",
              "&:hover": {
                color: "black",
              },
            }}
          >
            View Site
          </Button>
        </CardActions>
      </Card>
    </Grow>
  );
}
