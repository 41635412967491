import ccme from "../assets/ccme.jpeg";
import finallyforever from "../assets/finallyforever.jpeg";
import portfolio from "../assets/portfolio.jpg";
import watch from "../assets/watch.jpeg";
import weirdo from "../assets/weirdoswarm.png";

export const projContent = [
  {
    title: "Weirdo Swarm",
    text: "A fan website built for the band King Gizzard and the Lizard Wizard, showing their live sets, songs played live (and times played live), games, artists, etc. Full stack NextJS app with MongoDB database, user authentication, and over 1000 regular users that can track and review the shows/meetups they've been to, and see their own personal stats. The github repository is for the React site, before I rebuilt it in NextJS. The NextJS rebuild repository is private, but I am happy to share upon request. Deployed using Vercel with images served using a cdn.",
    image: weirdo,
    site: "https://weirdoswarm.org",
    github: "https://github.com/ydocsgnillats/weirdoswarm",
    path: "/1",
    technologies: [
      "React",
      "Sass",
      "NextJS",
      "Vercel",
      "image CDN",
      "MongoDB",
      "Typescript",
    ],
  },
  {
    title: "CCME",
    text: "A website built to allow people or businesses to easily accept cryptocurrency payments. The user creates an account, adds their various crypto wallets, and creates their own profile. The profile is customizable and allows the user to list their public addresses along with a button to easily copy the address for payment. Users can also generate a QR code that links directly to their profile, to be printed or displayed for easy use.",
    image: ccme,
    site: "https://www.ccme.io",
    github: "https://github.com/ydocsgnillats/ccme",
    path: "/2",
    technologies: ["react", "Moralis", "Tailwind"],
  },
  {
    title: "Developer Portfolio",
    text: "A website built to show off a developer's portfolio, including recent projects, downloadable resume, and a little about the developer.",
    image: portfolio,
    site: "https://www.codystallings.dev",
    github: "https://github.com/ydocsgnillats/portfolio",
    path: "/3",
    technologies: ["react", "Moralis", "Tailwind"],
  },
  {
    title: "Old School Watches",
    text: "A website built from an api for different popular watch brands and models. Users can scroll through or search watches by brand to see the different models and model numbers with all the related information.",
    image: watch,
    site: "https://www.oldschoolwatches.com",
    github: "https://github.com/ydocsgnillats/",
    path: "/4",
    technologies: ["react", "tailwind", "api for watch database"],
  },
  {
    title: "Finally Forever",
    text: "A website for a company specializing in personalized gift baskets and decoration setups. Users can read about the company, choose from their different packages, and add them to cart, as well as contact the owners for more personalized options.",
    image: finallyforever,
    site: "https://itsfinallyforever.com",
    github: "https://github.com/ydocsgnillats/finallyforever",
    path: "/5",
    technologies: ["react", "redux", "typescript", "Sass"],
  },
];
