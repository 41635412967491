import { Container } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WorkOutlinedIcon from "@mui/icons-material/WorkOutlined";
import ButtonGroup from "@mui/material/ButtonGroup";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Portfolio from "./pages/Portfolio";
import About from "./pages/About";
import Contact from "./pages/Contact";
// import TetraLogo from "./assets/tetralogo.png";
import TetraLogo from "./assets/Tetrachrome.svg";
// import Home from "./pages/Home";

import { loadFull } from "tsparticles";
import { useCallback } from "react";
import Particles from "react-particles";

const options = {
  particles: {
    number: {
      value: 80,
      density: {
        enable: true,
        area: 1500,
      },
    },
    color: {
      // value: ["#2EB67D", "#ECB22E", "#E01E5B", "#36C5F0"],
      // value: ["#d89653", "#3FB94C", "#91b7c2", "#ae374f"],
      value: ["#8BD4BE", "#25B8E4", "#FFA629", "#FF604A"],
    },
    shape: {
      type: "square",
    },
    opacity: {
      value: 1,
    },
    size: {
      value: { min: 1, max: 6 },
    },
    links: {
      enable: true,
      distance: 200,
      color: "#808080",
      opacity: 0.5,
      width: 1,
    },
    move: {
      enable: true,
      speed: 0.5,
      direction: "none",
      random: false,
      straight: false,
      outModes: "out",
    },
  },
  interactivity: {
    events: {
      onHover: {
        enable: true,
        mode: "grab",
      },
      onClick: {
        enable: true,
        mode: "push",
      },
    },
    modes: {
      grab: {
        distance: 140,
        links: {
          opacity: 1,
        },
      },
      push: {
        quantity: 4,
      },
    },
  },
};

function ButtonAppBar() {
  return (
    <Box className="flex sticky">
      <AppBar position="sticky md:mt-0 -mt-8 w-screen">
        <Toolbar className="md:justify-start p-2 bg-black flex-col md:flex-row md:h-12">
          <a href="/">
            <img src={TetraLogo} alt="Tetrachrome Logo" className="h-18 p-0" />
          </a>
          <ButtonGroup
            variant="text"
            aria-label="text button group"
            className="md:ml-8 text-sm z-50"
          >
            <Button
              sx={{
                padding: "0.5rem",
                "border-right": "1px solid white !important",
              }}
              href="/About"
              size="small"
              endIcon={<InfoOutlinedIcon />}
              color="inherit"
              className=""
            >
              About
            </Button>
            <Button
              sx={{
                padding: "0.5rem",
                "border-right": "1px solid white !important",
              }}
              href="/Portfolio"
              size="small"
              endIcon={<WorkOutlinedIcon />}
              color="inherit"
              className=""
            >
              Portfolio
            </Button>
            <Button
              sx={{ padding: "0.5rem" }}
              href="/Contact"
              size="smallm"
              endIcon={<ContactPageOutlinedIcon />}
              color="inherit"
              className=""
            >
              Contact
            </Button>
          </ButtonGroup>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

function App() {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  return (
    <>
      <ButtonAppBar />
      <Container
        disableGutters={true}
        maxWidth={false}
        fixed
        className="h-screen p-2 bg-zinc-950"
      >
        <Particles options={options} init={particlesInit} className="z-10" />
        <div className="flex flex-row flex-wrap justify-center mt-12 md:mt-36 md:mt-0 z-50">
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<About />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </BrowserRouter>
        </div>
        {/* <Footer /> */}
      </Container>
    </>
  );
}

export default App;
