import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState } from "react";
import { send } from "emailjs-com";
import { Typography } from "@mui/material";

export default function Contact() {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  let serviceID = process.env.REACT_APP_EMAIL_KEY;

  let toSend = {
    subject: subject,
    to: "tetrachome@gmail.com",
    type: "tetrachrome",
    body: message,
    from: email,
  };

  const onSubmit = () => {
    if (!email || !subject || !message) {
      return alert("Missing Info, please fill out all the boxes");
    }

    send(serviceID, "template_9b6mxbs", toSend, "KEvm0Q8ya6YoNh-Be")
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });

    alert("Message Sent!");
  };

  return (
    <div className="grid place-items-center p-4 justify-center z-50">
      <Typography
        variant="h6"
        component="div"
        className="text-white md:pb-12 pb-4 font-light md:font-medium w-2/3 text-center margin-auto"
      >
        Have any questions, ideas, or want to work with Tetrachrome?
      </Typography>
      <Typography
        variant="h4"
        component="div"
        className="m-auto text-white pb-4"
        style={{ margin: "auto" }}
      >
        Contact Us
      </Typography>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
        className="justify-center align-center flex flex-col m-auto"
      >
        <TextField
          id="outlined-basic"
          label="Email Address"
          variant="outlined"
          className="bg-white rounded-md text-white border-white"
          value={email}
          sx={{
            "& .MuiOutlinedInput-root.Mui-focused": {
              "& > fieldset": {
                borderColor: "white",
              },
            },
          }}
          onChange={(e) => setEmail(e.currentTarget.value)}
        />
        <TextField
          id="outlined-basic"
          label="Subject"
          variant="outlined"
          className="bg-white rounded-md"
          value={subject}
          sx={{
            "& .MuiOutlinedInput-root.Mui-focused": {
              "& > fieldset": {
                borderColor: "white",
              },
            },
          }}
          onChange={(e) => setSubject(e.currentTarget.value)}
        />
        <TextField
          id="outlined-multiline-static"
          label="Message"
          multiline
          rows={6}
          className="bg-white rounded-md"
          value={message}
          sx={{
            "& .MuiOutlinedInput-root.Mui-focused": {
              "& > fieldset": {
                borderColor: "white",
              },
            },
          }}
          onChange={(e) => setMessage(e.currentTarget.value)}
        />
        <Button
          sx={{
            padding: "1rem",
            backgroundColor: "#fff",
            fontSize: "1.1rem",
            border: "1px solid white !important",
          }}
          onClick={onSubmit}
          size="large"
          color="inherit"
          className="hover:text-white"
        >
          Send
        </Button>
      </Box>
    </div>
  );
}
