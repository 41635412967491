import * as React from "react";
import Typography from "@mui/material/Typography";

export default function About() {
  return (
    <>
      <div className="z-50 grid place-items-center text-white p-4 md:pt-12 mt-0 md:w-1/2 justify-center align-center">
        <Typography
          variant="h4"
          component="div"
          className="m-auto text-white pb-12"
          style={{ margin: "auto" }}
        >
          About Us
        </Typography>
        Tetrachrome LLC was founded in 2020 and has been established to create a
        single entity under which collective projects can be created. The main
        focus is in web development, specializing in store front, business, and
        portfolio pages. Have an idea you want to work with us on? Send it
        through the form on the contact page!
      </div>
    </>
  );
}
