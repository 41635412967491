import { projContent } from "../assets/projectContent";
import Card from "../components/Card";
import Typography from "@mui/material/Typography";

export default function Portfolio() {
  return (
    <div className="flex flex-col align-center justify-center">
      <Typography
        variant="h4"
        component="div"
        className="m-auto text-white pb-2"
        style={{ margin: "auto" }}
      >
        Projects
      </Typography>
      <div className="grid place-items-center">
        {projContent.map((i, key) => {
          return (
            <Card
              key={key}
              text={i.text}
              image={i.image}
              title={i.title}
              site={i.site}
            />
          );
        })}
      </div>
    </div>
  );
}
